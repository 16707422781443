import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Modal, Form, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { useAppSelector } from "../../../redux/hooks";
import { INSTRUCTOR } from "../../../utils/instructor";
import {  addBanner, addDepartment, addStory, editDepartment, getDepartment } from "../api";
import  { BannerSchema, StorySchema } from "../validations";
import styles from "../../instructor/styles/instructor.module.css";
import commonstyles from "../../instructor/styles/common.module.css";
import { UNIVERSITY } from "../../../utils/university";
// import { MESSAGES } from "../../../utils/messages";
import { AddUniversityProp, Department } from "./interface";
import { MESSAGES } from "../../../utils/messages";
import { uploadImage, uploadImages } from "../../common/utils";
import AddImage from "./UploadImageModal";

const AddOrEditBanner = ({ showModal, handleClose }: AddUniversityProp) => {
	useEffect(() => {
		departmentDetailsFormik.resetForm();
		setCroppedImage(null);
		setCroppedImageThumbnail(null);
	
	}, [showModal])
	
	 const { id } = useParams();


	 const [croppedImage,setCroppedImage]= useState<File | null>(null)

	 const [showModalImage, setShowModalImage] = useState<boolean>(false);
	 const handleCloseImage = () => {
		 setShowModalImage(false);}

		 const [croppedImageThumbnail,setCroppedImageThumbnail]= useState<File | null>(null)
	
useEffect(() => {
departmentDetailsFormik.setFieldValue("thumbnail",croppedImageThumbnail)

}, [croppedImageThumbnail])

	
	
	 const [showModalImageThumbnail, setShowModalImageThumbnail] = useState<boolean>(false);
	 const handleCloseImageThumbnail = () => {
		 setShowModalImageThumbnail(false);}

	const departmentDetailsFormik = useFormik({
		initialValues: {
            Title:"",
            image:null,
			thumbnail:null 
		},
        validationSchema: BannerSchema,
		onSubmit: (values) => {
			
			Promise.all([uploadImage(values.image)]).then((res)=>{
                console.log(res,"ress")
				toast.promise(
					addBanner(Number(id) ,res[0].data[0],values.Title),
					{
						pending: {
							render() {
								return "Adding Banner"
							},
						},
						success: {
							render() {
								departmentDetailsFormik.resetForm();
								handleClose();
	
								return "Successfully Added Banner"
							},
						},
						error: {
							render() {
								return "Failed to add Banner"
							},
						},
					}
				);

			})
		
			
		},

	});
	const handleAddDepartment = (e: React.SyntheticEvent<HTMLFormElement>) => {
		e.preventDefault();

		departmentDetailsFormik.submitForm();
	};

	return (
		<Modal
			show={showModal}
			onHide={() => {
				handleClose();
			}}
		>
			<div>
				<Modal.Header closeButton>
					<Modal.Title>
						Banner
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={handleAddDepartment}>
                    <Form.Group className="from-group" controlId="formFirstName">
								<Form.Label>Title</Form.Label>
								<Form.Control
									type="text"
									placeholder="Title"
									value={departmentDetailsFormik.values.Title}
									name="Title"
									onChange={departmentDetailsFormik.handleChange}
								/>
							</Form.Group>
							{departmentDetailsFormik.errors.Title &&
              departmentDetailsFormik.touched.Title ? (
									<span className={commonstyles.error}>
										{departmentDetailsFormik.errors.Title}
									</span>
								) : null}
						
                    <Form.Group className="from-group" controlId="formFirstName">
								<Form.Label>Banner</Form.Label>
								<Form.Control
									type="file"
									placeholder="Banner"
									name="image"
									
									accept="image/*"
									onChange={(e)=>{
										//@ts-ignore
										console.log(Array.from(e.target.files))
										//@ts-ignore
										
										departmentDetailsFormik.setFieldValue("image",e.target.files[0])}}
								/>
								{/* <Button onClick={()=>setShowModalImage(true)} className={`${styles.buttonss} mt-3`}>
						{croppedImage?"Update Story Image":"Upload Story Image"}
						
					</Button> */}
                                {departmentDetailsFormik.errors.image &&
              departmentDetailsFormik.touched.image ? (
									<span className={commonstyles.error}>
										{departmentDetailsFormik.errors.image}
									</span>
								) : null}
							</Form.Group>


							
							
						<Button className={`${styles.buttonss} mt-3`} type="submit">
							Add
						</Button>
					</Form>
				</Modal.Body>
			</div>
		

		</Modal>
	);
};

export default AddOrEditBanner;
