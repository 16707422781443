import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import { useNavigate, useParams } from "react-router-dom";
import CryptoJS from 'crypto-js';


import {
	addInstructor,
	editInstructor,
	getAllUniversities,
	getInstructor,
} from "../../api";
import {InstructorDetailsSchema} from "../../validations/addInstructor";
import {InstructorUpdateDetailsSchema} from "../../validations/addInstructor";

import { AddInstructorPayload, DepartmentInterface } from "../../api/interface";
import { UniversityInterface } from "../../api/interface";
import { MESSAGES } from "../../../../utils/messages";
import styles from "../../styles/instructor.module.css";
import commonstyles from "../../styles/common.module.css";
import { INSTRUCTOR } from "../../../../utils/instructor";
import { YUP } from "../../../../utils/validations";
import { APP_ROUTES } from "../../../../utils/routes";
import Layout from "../../../../components/Layout";
import AddImage from "./UploadImageModal";
import { uploadImage } from "../../../common/utils";
import { faCross, faEye, faEyeSlash, faRemove } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";




const AddInstructor = () => {

	
	
	const [universities, setUniversities] = useState<UniversityInterface[]>([]);
	const [department, setDepartment] = useState<DepartmentInterface[]>();
	const [selectedDepartments, setSelectedDepartments] = useState<number[]>([]);
	const [croppedImage,setCroppedImage]= useState<File | null>(null)
	const [showPassword, setShowPassword] = useState(false);

	

	
	const { id } = useParams();
	const navigate = useNavigate();
	const [showModal, setShowModal] = useState<boolean>(false);
	const removeUniversity=(index:any)=>{
const na=instructorDetailsFormik.values.universities;
const keys=Object.keys(na);
const keyToRemove=keys[index];
//@ts-ignore
delete na[keyToRemove]
instructorDetailsFormik.setFieldValue("universities",na)
	}
	const handleClose = () => {
		setShowModal(false);}
	const instructorDetailsFormik = useFormik({
		initialValues: {
			firstName: "",
			lastName: "",
			university: 0,
			department: [],
			email: "",
			phoneNumber: "",
			password: "",
			role:"",
			universities:{"0":{id:"0",dep:[],allDep:[]}}
		},
		enableReinitialize: true,
		onSubmit: (values) => {

			let uniPayload:any={};
			Object.entries(instructorDetailsFormik.values.universities).map(([key, value]) => {
				if(value.id!=="0")
				{
 uniPayload[value.id]=value.dep
// const calDep=value.dep.map((depa)=>{
// 	return {depa}
// })
// uniPayload[value.id]=calDep
		}})
              


			const mobileNumber = parsePhoneNumber(values.phoneNumber);

			const payload: any = {
				instructor_role:values.role,
				first_name: values.firstName,
				last_name: values.lastName,
				
				universities: uniPayload,
				country_code: mobileNumber?.countryCallingCode as string,
				mobile: mobileNumber?.nationalNumber as string,

			};
			
				payload.password=values.password
				payload.encrypted_password= CryptoJS.AES.encrypt(values.password, process.env.REACT_APP_PASSWORD_KEY as string).toString();
			
			
			console.log(croppedImage,"ciiiiiiii");
			if(croppedImage){
			uploadImage(croppedImage).then((res)=>{
				payload.avatar=res.data[0]
				toast.promise(
					!id ? addInstructor(payload) : editInstructor(Number(id), payload),
					{
						pending: {
							render() {
								return !id ? MESSAGES.INSTRUCTOR.ADD : MESSAGES.INSTRUCTOR.EDIT;
							},
						},
						success: {
							render() {
								instructorDetailsFormik.resetForm();
								navigate(APP_ROUTES.INSTRUCTORS_LIST);
	
								return !id
									? MESSAGES.INSTRUCTOR.SUCCESS
									: MESSAGES.INSTRUCTOR.EDITED;
							},
						},
						error: {
							render() {
								return !id
									? MESSAGES.INSTRUCTOR.INVALID
									: MESSAGES.INSTRUCTOR.NOTEDITED;
							},
						},
					}
				);}
				
			
			
			
			)}
			else{
				toast.promise(
					!id ? addInstructor(payload) : editInstructor(Number(id), payload),
					{
						pending: {
							render() {
								return !id ? MESSAGES.INSTRUCTOR.ADD : MESSAGES.INSTRUCTOR.EDIT;
							},
						},
						success: {
							render() {
								instructorDetailsFormik.resetForm();
								navigate(APP_ROUTES.INSTRUCTORS_LIST);
	
								return !id
									? MESSAGES.INSTRUCTOR.SUCCESS
									: MESSAGES.INSTRUCTOR.EDITED;
							},
						},
						error: {
							render() {
								return !id
									? MESSAGES.INSTRUCTOR.INVALID
									: MESSAGES.INSTRUCTOR.NOTEDITED;
							},
						},
					}
				)
			}
			
			
				
				
		
			
		},
		validationSchema: InstructorDetailsSchema,
	});
	const addMoreUniversity=()=>{
		const cl=Object.keys(instructorDetailsFormik.values.universities).length;
		const no={...instructorDetailsFormik.values.universities,[cl]:{id:0,dep:[]}}
		instructorDetailsFormik.setFieldValue("universities",no)
	}


	const handleUniversitiesChange = (
		e:any,key:any
	):any => {
		setSelectedDepartments([])
		const universiti=instructorDetailsFormik.values.universities;
		const deps=addDepartments(Number(e.target.value))
		const no={...universiti,[key]:{id:e.target.value,dep:[],allDep:deps}}
		console.log(no)
		





		instructorDetailsFormik.setFieldValue(
			"universities",
			no
		);
		// instructorDetailsFormik.setFieldValue(INSTRUCTOR.FORMIK.DEPARTMENT, 0);
		// addDepartments(Number(e.target.value));
	};

	const addDepartments = (id: Number) => {	
		const selectedUniversity = universities.filter(
			(uni: UniversityInterface) => {
				return Number(uni.id) === Number(id);
			}
		);
		console.log(universities, selectedUniversity, "compare")
		console.log(selectedUniversity[0]?.universityDepartments, "suuuu")
		setDepartment(selectedUniversity[0]?.universityDepartments);
		return selectedUniversity[0]?.universityDepartments
	};

	const handleAddInstructor = (e: React.SyntheticEvent<HTMLFormElement>) => {
		e.preventDefault();
		instructorDetailsFormik.submitForm();
	};

	useEffect(() => {
		getAllUniversities().then((res) => {
			setUniversities(res?.data);
		})
	
	}, []);

	useEffect(() => {
		if (id) {
			console.log("hittt");
			getInstructor({ id: Number(id),for_detail:true }).then((res) => {
				const {
					data: {
						country_code,
						mobile,
						instructorUniversities,
						first_name,
						last_name,
						email,
						instructorDepartments,
						encrypted_password,
						instructor_role
					},
				} = res;
				if(instructorUniversities.length)
				addDepartments(instructorUniversities[0].university_id);
				instructorDetailsFormik.setFieldValue(
					INSTRUCTOR.FORMIK.FIRSTNAME,
					first_name
				);
				instructorDetailsFormik.setFieldValue(
					INSTRUCTOR.FORMIK.LASTNAME,
					last_name
				);
				instructorDetailsFormik.setFieldValue(
					"role",
					instructor_role
				);
				instructorDetailsFormik.setFieldValue(
					INSTRUCTOR.FORMIK.PHONENUMBER,
					`${country_code && "+"}${country_code}${mobile}`
				);
				if(encrypted_password)
				{const bytes = CryptoJS.AES.decrypt(encrypted_password as string, process.env.REACT_APP_PASSWORD_KEY as string);
				const originalPassword = bytes.toString(CryptoJS.enc.Utf8);
				instructorDetailsFormik.setFieldValue("password", originalPassword);}
			const iu=instructorUniversities;
			const ro:any={}
			instructorUniversities.map((el:any)=>{
				const arr:any=[];
				el.departments.map((t:any)=>{
					arr.push(t.department_id.toString())
				})
				ro[el.university_id]={id:el.university_id,dep:arr,allDep:addDepartments(el.university_id)}
				
			})
console.log(ro,"rooo")
				if(instructorUniversities.length)
				instructorDetailsFormik.setFieldValue(
					"universities",
					ro
				);
				if(instructorUniversities.length)
				addDepartments(Number(instructorUniversities[0].university_id));
				const depList = instructorDepartments.map((dep) => dep.department_id);
				if (depList.length) setSelectedDepartments(depList as number[]);
				//instructorDetailsFormik.setFieldValue(INSTRUCTOR.FORMIK.DEPARTMENT, instructorDepartments[0].department_id)
			});
		}

		if (!id) {
			instructorDetailsFormik.resetForm();
			setDepartment([]);
			setSelectedDepartments([]);
		}
	
	}, [universities])
	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	  };
	

	const handleCheckboxClick = (e: React.ChangeEvent<HTMLInputElement>,key:any) => {
		//@ts-ignore
		const cv=instructorDetailsFormik.values.universities[key].dep;
				//@ts-ignore
		const cvo=instructorDetailsFormik.values.universities[key]
		if (e.target.checked === true) {
			//@ts-ignore
			instructorDetailsFormik.setFieldValue("universities",{...instructorDetailsFormik.values.universities,[key]:{...cvo,dep:[e.target.value,...cv]}})
		} else {
			const selectedDepartmentsCopy = cv;
			const selectedDepartmentsList = selectedDepartmentsCopy.filter((num:any) => {
				return Number(num) !== Number(e.target.value);
			});
				//@ts-ignore
				instructorDetailsFormik.setFieldValue("universities",{...instructorDetailsFormik.values.universities,[key]:{...cvo,dep:selectedDepartmentsList}})
		}
		console.log(instructorDetailsFormik.values.universities)
	};

	return (
		<Layout>
			<div
				className={`${styles["add-lead-from-page"]} ${styles["add-lead-from"]}`}
			>
				<h1>{id ? "Edit Instructor" : "Add New Instructor"}</h1>

				<Form onSubmit={handleAddInstructor}>
					<div className={styles.fieldStyle}>
						<Form.Group onClick={()=>console.log(instructorDetailsFormik.errors)} className="from-group" controlId="formFirstName">
							<Form.Label>First name</Form.Label>
							<Form.Control
								type="text"
								placeholder="First Name"
								value={instructorDetailsFormik.values.firstName}
								name="firstName"
								onChange={instructorDetailsFormik.handleChange}
							/>
						</Form.Group>
						{instructorDetailsFormik.errors.firstName &&
          instructorDetailsFormik.touched.firstName ? (
								<span className={commonstyles.error}>
									{instructorDetailsFormik.errors.firstName}
								</span>
							) : null}
					</div>
					<Button onClick={()=>setShowModal(true)} className={`${styles.buttonss} mt-3`}>
						{croppedImage?"Update Profile Image":"Upload Profile Image"}
						
					</Button>

					<div className={styles.fieldStyle}>
						<Form.Group className="from-group" controlId="formLastName">
							<Form.Label>Last name</Form.Label>
							<Form.Control
								type="text"
								placeholder="Last Name"
								value={instructorDetailsFormik.values.lastName}
								onChange={instructorDetailsFormik.handleChange}
								name="lastName"
							/>
						</Form.Group>
						{instructorDetailsFormik.errors.lastName &&
          instructorDetailsFormik.touched.lastName ? (
								<span className={commonstyles.error}>
									{instructorDetailsFormik.errors.lastName}
								</span>
							) : null}
					</div>
					<div className={styles.fieldStyle}>
						<Form.Group className="from-group" controlId="formLastName">
							<Form.Label>Role</Form.Label>
							<Form.Control
								type="text"
								placeholder="Role"
								value={instructorDetailsFormik.values.role}
								onChange={instructorDetailsFormik.handleChange}
								name="role"
							/>
						</Form.Group>
						{instructorDetailsFormik.errors.role &&
          instructorDetailsFormik.touched.role ? (
								<span className={commonstyles.error}>
									{instructorDetailsFormik.errors.role}
								</span>
							) : null}
					</div>

					<div className={styles.fieldStyle}>
						<Form.Group controlId="formPhoneNumber">
							<Form.Label>Phone Number</Form.Label>
							<PhoneInput
								international
								placeholder="Enter phone number"
								value={instructorDetailsFormik.values.phoneNumber}
								name="phoneNumber"
								onChange={(value) =>
									instructorDetailsFormik.setFieldValue(
										INSTRUCTOR.FORMIK.PHONENUMBER,
										value
									)
								}
							/>
						</Form.Group>
						{instructorDetailsFormik.errors.phoneNumber &&
          instructorDetailsFormik.touched.phoneNumber ? (
								<span className={commonstyles.error}>
									{instructorDetailsFormik.errors.phoneNumber}
								</span>
							) : null}
					</div>

					

					<div style={{position: "relative"}} className={styles.fieldStyle}>
						<Form.Group className="from-group" controlId="formPassword">
							<Form.Label>Password</Form.Label>
							<Form.Control
								        type={showPassword ? 'text' : 'password'}

								placeholder="Password"
								value={instructorDetailsFormik.values.password}
								name="password"
								onChange={instructorDetailsFormik.handleChange}
							/>
							<span onClick={togglePasswordVisibility}  style={{
          position: 'absolute',
          top: '70%',
          right: '10px',
          cursor: 'pointer',
          transform: 'translateY(-50%)',
        }}>
							 <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
						</span></Form.Group>
						{instructorDetailsFormik.errors.password &&
          instructorDetailsFormik.touched.password ? (
								<span className={commonstyles.error}>
									{instructorDetailsFormik.errors.password}
								</span>
							) : null}
					</div>
					<hr />
					{/* <div onClick={()=>console.log(instructorDetailsFormik.values)} className={styles.fieldStyle}>
						<Form.Group className="from-group" controlId="formUniversity">
							<Form.Label>University</Form.Label>
							<Form.Control
							
								value={instructorDetailsFormik.values.university}
								as="select"
								onChange={handleUniversityChange}
							>
								<option value={0} key={0}>
                Select University
								</option>
								{universities?.map((university, id) => (
									<option value={university.id} key={id}>
										{university.name}
									</option>
								))}
							</Form.Control>
						</Form.Group>
						{instructorDetailsFormik.errors.university &&
          instructorDetailsFormik.touched.university ? (
								<span className={commonstyles.error}>
									{instructorDetailsFormik.errors.university}
								</span>
							) : null}



					</div>
					{instructorDetailsFormik.values.university > 0 && (
						<div className={styles.fieldStyle}>
							<Form.Label>Departments</Form.Label>

							{department?.map((res, id) => {
								return (
									<Form.Check
										key={id}
							</span>			className="from-check"
										value={res.id as number}
										type="checkbox"
										checked={
											selectedDepartments.includes(res.id as number)
												? true
												: false
										}
										onChange={(e:any,ke)=>handleCheckboxClick}
										label={res.name}
									></Form.Check>
								);
							})}

							{instructorDetailsFormik.errors.department &&
            instructorDetailsFormik.touched.department ? (
									<span className={commonstyles.error}>
										{instructorDetailsFormik.errors.department}
									</span>
								) : null}
						</div>
					)} */}
<Button onClick={addMoreUniversity}>Add More university</Button>

<div onClick={()=>console.log(instructorDetailsFormik.values)} className={styles.fieldStyle}>
{Object.entries(instructorDetailsFormik.values.universities).map(([key, value,],index) => (
        <>
		<Form.Group className="from-group" controlId="formUniversity">
		<Form.Label style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>University {index!==0&&<FontAwesomeIcon style={{cursor:"pointer"}} onClick={()=>{removeUniversity(index)}} icon={faRemove}/>}</Form.Label>
		<Form.Control
		
			 value={
				//@ts-ignore
				instructorDetailsFormik.values.universities[key].id}
			as="select"
			 onChange={(e:any)=>handleUniversitiesChange(e,key)}
		>
			<option value={0} key={0}>
Select University
			</option>
			{universities?.map((university, id) => (
				<option value={university.id} key={id}>
					{university.name}
				</option>
			))}
		</Form.Control>
		{instructorDetailsFormik.errors.universities &&
          instructorDetailsFormik.touched.universities && 
		 //@ts-ignore 
		  instructorDetailsFormik.errors?.universities[index] ? (
								<span className={commonstyles.error}>
									{
										//@ts-ignore
									instructorDetailsFormik.errors?.universities[index]?.id}
								</span>
							) : null}
	</Form.Group>
	{/* {instructorDetailsFormik.errors.university &&
instructorDetailsFormik.touched.university ? (
			<span className={commonstyles.error}>
				{instructorDetailsFormik.errors.university}
			</span>
		) : null} */}

{
	//@ts-ignore
instructorDetailsFormik.values.universities[key].id !== "0" && (
						<div className={styles.fieldStyle}>
							<Form.Label>Departments</Form.Label>

							{
							//@ts-ignore
							instructorDetailsFormik.values.universities[key].allDep?.map((res, id) => {
								return (
									<Form.Check
										key={id}
										className="from-check"
										value={res.id as number}
										type="checkbox"
										checked={
											//@ts-ignore
											instructorDetailsFormik.values.universities[key].dep.includes(res.id.toString() )
												? true
												: false
										}
										onChange={(e:any)=>{handleCheckboxClick(e,key)}}
										label={res.name}
									></Form.Check>
								);
							})}

							{/* {instructorDetailsFormik.errors.department &&
            instructorDetailsFormik.touched.department ? (
									<span className={commonstyles.error}>
										{instructorDetailsFormik.errors.department}
									</span>
								) : null} */}
						</div>
					)}
					{instructorDetailsFormik.errors.universities &&
          instructorDetailsFormik.touched.universities && 
		 //@ts-ignore 
		  instructorDetailsFormik.errors?.universities[index] ? (
								<span className={commonstyles.error}>
									{
										//@ts-ignore
									instructorDetailsFormik.errors?.universities[index]?.dep}
								</span>
							) : null}
		</>
      ))}

							
					</div>
					{/* <Button variant="outline-secondary" className="mb-2">
							<i className="fas fa-plus"></i> Choose More Department
						</Button> */}
					<hr />
					{/* <Button variant="outline-secondary" className="mb-2">
							<i className="fas fa-plus"></i> Add More University
						</Button> */}
					<Button className={`${styles.buttonss} mt-3`} type="submit">
						{id ? INSTRUCTOR.EDIT : INSTRUCTOR.ADD}
					</Button>
				</Form>
			</div>
			<AddImage croppedImage={croppedImage} setCroppedImage={setCroppedImage} showModal={showModal} handleClose={handleClose} />
		</Layout>
	);
};

export default AddInstructor;
