import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Modal, Form, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { useAppSelector } from "../../../redux/hooks";
import { INSTRUCTOR } from "../../../utils/instructor";
import {  addDepartment, addStory, editDepartment, getDepartment } from "../api";
import  { StorySchema } from "../validations";
import styles from "../../instructor/styles/instructor.module.css";
import commonstyles from "../../instructor/styles/common.module.css";
import { UNIVERSITY } from "../../../utils/university";
// import { MESSAGES } from "../../../utils/messages";
import { AddUniversityProp, Department } from "./interface";
import { MESSAGES } from "../../../utils/messages";
import { uploadImage, uploadImages } from "../../common/utils";
import AddImage from "./UploadImageModal";

const AddOrEditStory = ({ showModal, handleClose }: AddUniversityProp) => {
	useEffect(() => {
		departmentDetailsFormik.resetForm();
		setCroppedImage(null);
		setCroppedImageThumbnail(null);
	
	}, [showModal])
	
	 const { id } = useParams();


	 const [croppedImage,setCroppedImage]= useState<File | null>(null)

	 const [showModalImage, setShowModalImage] = useState<boolean>(false);
	 const handleCloseImage = () => {
		 setShowModalImage(false);}

		 const [croppedImageThumbnail,setCroppedImageThumbnail]= useState<File | null>(null)
	
useEffect(() => {
departmentDetailsFormik.setFieldValue("thumbnail",croppedImageThumbnail)

}, [croppedImageThumbnail])

	
	
	 const [showModalImageThumbnail, setShowModalImageThumbnail] = useState<boolean>(false);
	 const handleCloseImageThumbnail = () => {
		 setShowModalImageThumbnail(false);}

	const departmentDetailsFormik = useFormik({
		initialValues: {
            Title:"",
            image:null,
			thumbnail:null 
		},
        validationSchema: StorySchema,
		onSubmit: (values) => {
			
			Promise.all([uploadImages(values.image),uploadImage(croppedImageThumbnail)]).then((res)=>{
				toast.promise(
					addStory(Number(id) ,res[0].data,res[1].data[0],values.Title),
					{
						pending: {
							render() {
								return "Adding Story"
							},
						},
						success: {
							render() {
								departmentDetailsFormik.resetForm();
								handleClose();
	
								return "Successfully Added Story"
							},
						},
						error: {
							render() {
								return "Failed to add story"
							},
						},
					}
				);

			})
		
			
		},

	});
	const handleAddDepartment = (e: React.SyntheticEvent<HTMLFormElement>) => {
		e.preventDefault();

		departmentDetailsFormik.submitForm();
	};

	return (
		<Modal
			show={showModal}
			onHide={() => {
				handleClose();
			}}
		>
			<div>
				<Modal.Header closeButton>
					<Modal.Title>
						Story
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={handleAddDepartment}>
                    <Form.Group className="from-group" controlId="formFirstName">
								<Form.Label>Title</Form.Label>
								<Form.Control
									type="text"
									placeholder="Title"
									value={departmentDetailsFormik.values.Title}
									name="Title"
									onChange={departmentDetailsFormik.handleChange}
								/>
							</Form.Group>
							{departmentDetailsFormik.errors.Title &&
              departmentDetailsFormik.touched.Title ? (
									<span className={commonstyles.error}>
										{departmentDetailsFormik.errors.Title}
									</span>
								) : null}
						
                    <Form.Group className="from-group" controlId="formFirstName">
								<Form.Label>Story</Form.Label>
								<Form.Control
									type="file"
									placeholder="Story"
									name="image"
									multiple={true}
									accept="image/*"
									onChange={(e)=>{
										//@ts-ignore
										console.log(Array.from(e.target.files))
										//@ts-ignore
										
										departmentDetailsFormik.setFieldValue("image",Array.from(e.target.files))}}
								/>
								{/* <Button onClick={()=>setShowModalImage(true)} className={`${styles.buttonss} mt-3`}>
						{croppedImage?"Update Story Image":"Upload Story Image"}
						
					</Button> */}
                                {departmentDetailsFormik.errors.image &&
              departmentDetailsFormik.touched.image ? (
									<span className={commonstyles.error}>
										{departmentDetailsFormik.errors.image}
									</span>
								) : null}
							</Form.Group>


							<Form.Group className="from-group" controlId="formFirstName">
								<Form.Label>Story Thumbnail</Form.Label>
								<Button onClick={()=>setShowModalImageThumbnail(true)} className={`${styles.buttonss} mt-3`}>
						{croppedImageThumbnail?"Update Story Thumbnail":"Upload Story Thumbnail"}
						
					</Button>
                                {departmentDetailsFormik.errors.thumbnail &&
              departmentDetailsFormik.touched.thumbnail ? (
									<span className={commonstyles.error}>
										{departmentDetailsFormik.errors.thumbnail}
									</span>
								) : null}
							</Form.Group>
							
						<Button className={`${styles.buttonss} mt-3`} type="submit">
							Add
						</Button>
					</Form>
				</Modal.Body>
			</div>
			<AddImage croppedImage={croppedImage} setCroppedImage={setCroppedImage} showModal={showModalImage} handleClose={handleCloseImage} />
			<AddImage croppedImage={croppedImageThumbnail} setCroppedImage={setCroppedImageThumbnail} showModal={showModalImageThumbnail} handleClose={handleCloseImageThumbnail} />

		</Modal>
	);
};

export default AddOrEditStory;
