import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { Dropdown, Spinner } from "react-bootstrap";
import DraggableList from "react-draggable-list";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEllipsisH,
  faEye,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

import Layout from "../../../../components/Layout/index";
import commonStyles from "../../../common/styles/common.module.css";
import {
  changeCategoryOrder,
  commonDepartmentList,
  departmentList,
  removeCategory,
  removeDepartment,
} from "../../api";
import Pagination from "../../../../components/Pagination";
import { PaginationInterface } from "../../../../components/Pagination/interfaces";
import { AddOrEditDepartment } from "../../modals";
import { INSTRUCTOR } from "../../../../utils/instructor";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { departmentModal } from "../../../../redux/reducers/department";
import { MESSAGES } from "../../../../utils/messages";
import { Department as DepartmentInterface } from "../../api/interface";
import AddOrEditCommonDepartment from "../../modals/AddOrEditComonDepartment";
import { Avatar } from "../../../../assets/images";
import { Item } from "./Item";

const CommonDepartment = () => {
  const { id } = useParams();
  interface ListItem {
    id: number;
  }

  // Define the props for the Item component
  interface ItemProps {
    item: ListItem;
    itemSelected?: boolean; // Optional, adjust based on your usage
    dragHandleProps: {
      onMouseDown: (e: React.MouseEvent) => void;
      onTouchStart: (e: React.TouchEvent) => void;
    };
  }

  const navigate = useNavigate();
  const [list, setList] = useState<any>([]);

  const containerRef = useRef<HTMLDivElement | null>(null);

  const _onListChange = (newList: any) => {
    // console.log(newList,"List")
    const listOrder = newList.map((cat: any) => {
      return cat.id;
    });

    setList(newList);
	changeCategoryOrder(listOrder).then((res)=>{
		setList(newList);

	}).catch((err)=>{
		setList(list);
	})

    // toast.promise(changeCategoryOrder(listOrder), {
    //   pending: {
    //     render() {
    //       return "Changing Order";
    //     },
    //   },
    //   success: {
    //     render() {
    //       setList(newList);
    //       return "Order change Successfully";
    //     },
    //   },
    //   error: {
    //     render() {
    //       setList(list);
    //       return "Order Change Failed";
    //     },
    //   },
    // });
  };

  const [department, setDepartment] = useState<DepartmentInterface[]>([]);
  const [pagination, setPagination] = useState<PaginationInterface>({
    page: 1,
    limit: 10,
    count: 0,
    totalPages: 0,
  });
  const [showModal, setShowModal] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(true);

  const selectedDepartment = useAppSelector((state) => state.departmentReducer);

  const handleRemoveDepartment = (id: number) => {
    toast.promise(removeCategory({ id: id }), {
      pending: {
        render() {
          return MESSAGES.DEPARTMENT.REMOVING;
        },
      },
      success: {
        render({}) {
          refresh();
          return MESSAGES.DEPARTMENT.REMOVED;
        },
      },
      error: {
        render() {
          return MESSAGES.DEPARTMENT.NOTREMOVED;
        },
      },
    });
  };
  const refresh = () => {
    setLoading(true);
    const query = {
      per_page: pagination.limit,
      page: pagination.page,
      university_id: id,
    };

    commonDepartmentList(query, { type: 1 }).then((res) => {
      const {
        paginate: { total, last_page },
        data,
      } = res;
      setPagination({ ...pagination, count: total, totalPages: last_page });
      setDepartment(data);
      setList(data);
      setLoading(false);
    });
  };
  useEffect(() => {
    refresh();
  }, []);
  useEffect(() => {
    refresh();
  }, [pagination.page]);

  const handleClose = () => {
    setShowModal(false);
    if (selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.ADD) {
      if (pagination.page === 1) {
        refresh();
      } else {
        setPagination({ page: 1, limit: 1000, count: 0, totalPages: 0 });
      }
    } else {
      refresh();
    }
  };

  return (
    <Layout>
      <div className={commonStyles["outtre-pad"]}>
        <>
          <span className="instr_manager d-flex justify-content-between align-items-center">
            <h3>University Management / Category</h3>

            <div className={commonStyles["heaing-filter"]}>
              {/* <div className={commonStyles["filter-left"]}>
                  <span>
                    Filter:
                    <select>
                      <option>Location</option>
                      <option>This Week</option>
                    </select>
                  </span>
                  <span>
                    Filter:
                    <select>
                      <option>Price</option>
                      <option>This Week</option>
                    </select>
                  </span>
                  <span>
                    Filter:
                    <select>
                      <option>Department</option>
                      <option>This Week</option>
                    </select>
                  </span>
                  <span>
                    Filter:
                    <select>
                      <option>This Week</option>
                      <option>This Week</option>
                    </select>
                  </span>
                </div> */}
            </div>
          </span>
        </>
        <div className={commonStyles["heaing-filter"]}>
          <h3></h3>
          {/* <div className={commonStyles["filter-left"]}>
						<span>Filter: <select>
							<option>Location</option>
							<option>This Week</option>
						</select></span>
						<span>Filter: <select>
							<option>Price</option>
							<option>This Week</option>
						</select></span>
						<span>Filter: <select>
							<option>Department</option>
							<option>This Week</option>
						</select></span>
						<span>Filter: <select>
							<option>This Week</option>
							<option>This Week</option>
						</select></span>
					</div> */}
        </div>
        {!loading ? (
          <div className={commonStyles["table-data"]}>
            <div className={`${commonStyles["property-head"]} pb-1`}>
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  {/* <button
									className={`nav-link ${commonStyles.activeTab}`}
									id="pills-home-tab"
									data-bs-toggle="pill"
									data-bs-target="#pills-home"
									type="button"
									role="tab"
									aria-controls="pills-home"
									aria-selected="true"
								>
                  All
								</button> */}
                </li>
              </ul>
              <div className={commonStyles["filter-pro"]}>
                <button
                  onClick={() => {
                    dispatch(
                      departmentModal({
                        id: 0,
                        editOrAdd: INSTRUCTOR.REDUX.ADD,
                      })
                    );
                    setShowModal(true);
                  }}
                  className={commonStyles["add-lead"]}
                >
                  <FontAwesomeIcon icon={faPlus} /> Add New Category
                </button>
              </div>
            </div>
            <div className="tab-content" id="pills-tabContent">
              <div
                style={{ touchAction: "pan-y" }}
                ref={containerRef}
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <table>
                  <thead>
                    <tr
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-around",
                      }}
                    >
                      <th style={{ width: "250px", textAlign: "center" }}>
                        Category
                      </th>
                      <th style={{ width: "250px", textAlign: "center" }}>
                        Action
                      </th>
                      <th style={{ width: "250px", textAlign: "center" }}>
                        Order
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <DraggableList
                      itemKey="id"
                      //@ts-ignore
                      template={(props) => (
                        <Item
                          {...props}
                          setShowModal={setShowModal}
                          handleRemoveDepartment={handleRemoveDepartment}
                        />
                      )}
                      list={list}
                      onMoveEnd={(newList: any) => _onListChange(newList)}
                      container={() => containerRef.current}
                    />
                    {/* {list.map((item) => (
          <Item item={item} />
        ))} */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" role="status"></Spinner>
          </div>
        )}
      </div>

      {/* <Pagination
				paginationState={pagination}
				setPaginationState={setPagination}
			/> */}
      <AddOrEditCommonDepartment
        showModal={showModal}
        handleClose={handleClose}
      />
    </Layout>
  );
};

export default CommonDepartment;
