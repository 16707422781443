import React, { useEffect, useState } from "react";
import { AddInstructorProp } from "./interface";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { Button, Form, Modal } from "react-bootstrap";

import { addCourse, course, editCourse } from "../api";
import styles from "../../instructor/styles/instructor.module.css";
import commonstyles from "../../instructor/styles/common.module.css";
import { CourseAddDetailsSchema, CourseDetailsSchema } from "../validations";
import { INSTRUCTOR } from "../../../utils/instructor";
import { useAppSelector } from "../../../redux/hooks";
import {
	DepartmentInterface,
	Instructor,
	UniversityInterface,
} from "../../instructor/api/interface";
import {
	getAllInstructorUniversity,
	getAllUniversities,
} from "../../instructor/api";
import { AddCourseParam } from "../api/interface";
import { PaginationInterface } from "../../../components/Pagination/interfaces";
import { COURSES } from "../../../utils/courses";
import { MESSAGES } from "../../../utils/messages";
import { YUP } from "../../../utils/validations";
import { uploadImage } from "../../common/utils";
import { commonDepartmentList, getSemesters } from "../../university/api";
import { on } from "events";
import moment from "moment";

const AddorEdit = ({ showModal, handleClose,university }: any) => {
	const selectedCourse = useAppSelector((state) => state.courseReducer);
	const [instructors, setInstructors] = useState<Instructor[]>([]);
	const [universities, setUniversities] = useState<UniversityInterface[]>([]);
	const [department, setDepartment] = useState<DepartmentInterface[]>();
	const [semester, setSemester] = useState<any[]>();

	const [selectedDepartments, setSelectedDepartments] = useState<number[]>([]);
	const [selectedSemesters, setSelectedSemesters] = useState<number[]>([]);


	const [videoFile, setVideoFile] = useState<File|null>(null);
	const [allCategory,setAllCategory]=useState<any>([])

	
	const handleFileChange = (e:any) => {
		setVideoFile(e.target.files[0]);
	  };
	
	const [pagination, setPagination] = useState<PaginationInterface>({
		page: 1,
		limit: 10,
		count: 0,
		totalPages: 0,
	});

	const [loading, setLoading]=useState<boolean>(false)

	const addInstructor = (id: number) => {
		getAllInstructorUniversity(id).then((res) => {
			setInstructors(res.data);
			console.log(setPagination);
		});
	};
	useEffect(() => {
		getAllUniversities().then((res) => {
			setUniversities(res?.data);
		});
	}, [showModal]);
	
	useEffect(() => {
		if (selectedCourse.editOrAdd === INSTRUCTOR.REDUX.EDIT) {
			course(String(selectedCourse.id), pagination).then((res) => {
				const { name, instructor, university, departments,  whatsapp_link, discount, description, course_type, semesters, code,category_id,online_price,in_person_price,discount_type} = res.data;

				courseDetailsFormik.setFieldValue(COURSES.FORMIK.COURSE, name);
				courseDetailsFormik.setFieldValue("discount", discount)
				courseDetailsFormik.setFieldValue("discount_type", discount_type)

				courseDetailsFormik.setFieldValue(COURSES.FORMIK.LINK, whatsapp_link)
				courseDetailsFormik.setFieldValue(COURSES.FORMIK.DESCRIPTION, description)
				courseDetailsFormik.setFieldValue(COURSES.FORMIK.COURSE_TYPE, course_type)
				if(Number(course_type)===3)
				{
					courseDetailsFormik.setFieldValue("onlinePrice", online_price)
					courseDetailsFormik.setFieldValue("inPersonPrice", in_person_price)


					
			

				}
				else if(Number(course_type)===2){

					courseDetailsFormik.setFieldValue(COURSES.FORMIK.PRICE, in_person_price)

				}
				else{
					courseDetailsFormik.setFieldValue(COURSES.FORMIK.PRICE, online_price)

				}

				courseDetailsFormik.setFieldValue("category", category_id)

				courseDetailsFormik.setFieldValue("code", code)


				addInstructor(university.id as number);
				courseDetailsFormik.setFieldValue(
					COURSES.FORMIK.INSTRUCTOR,
					instructor.id
				);
				courseDetailsFormik.setFieldValue(
					COURSES.FORMIK.UNIVERSITY,
					university.id
				);
				addDepartments(Number(university.id));
				addSemesters(Number(university.id))
				const depList = departments.map((dep) => dep.department_id);
				setSelectedDepartments(depList);
				const semList = semesters.map((sem:any) => sem.semester_id);
				setSelectedSemesters(semList);
			});
		} else {
			courseDetailsFormik.resetForm();
			setDepartment([]);
			setSelectedDepartments([]);
			setSemester([])
		}
	}, [selectedCourse.editOrAdd, showModal]);

	const addDepartments = (id: Number) => {
		if (id === 0) {
			setDepartment([]);
			return;
		}
		const selectedUniversity = universities.filter(
			(uni: UniversityInterface) => {
				return uni.id === id;
			}
		);
		setDepartment(selectedUniversity[0]?.universityDepartments);
	};
	const addSemesters = (id: Number) => {
		if (id === 0) {
			setSemester([]);
			return;
		}
		getSemesters({},id as number).then((res)=>{
			setSemester(res.data);
		})
	
		
	};
	const handleUniversityChange = (e: any) => {
		courseDetailsFormik.handleChange(e);
		addDepartments(Number(e.target.value));
		addSemesters(Number(e.target.value))
		setSelectedDepartments([]);
		setSelectedSemesters([]);
		courseDetailsFormik.setFieldValue("instructor",0)
		
		if (Number(e.target.value) === 0) {
			setInstructors([]);
			return;
		}
		addInstructor(Number(e.target.value));
	};
	useEffect(() => {
		addDepartments(Number(university));
		addSemesters(Number(university))
		if (Number(university) === 0) {
			setInstructors([]);
			return;
		}
		addInstructor(Number(university));
	}, [showModal])
	

	const handleCheckboxClick = (e: React.ChangeEvent<HTMLInputElement>) => {
		courseDetailsFormik.setFieldError(COURSES.FORMIK.DEPARTMENT, "");
		if (e.target.checked === true) {
			setSelectedDepartments([...selectedDepartments, Number(e.target.value)]);
		} else {
			const selectedDepartmentsCopy = selectedDepartments;
			const selectedDepartmentsList = selectedDepartmentsCopy.filter((num) => {
				return num !== Number(e.target.value);
			});
			setSelectedDepartments(selectedDepartmentsList);
		}
	};
	
	const handleSemesterCheckboxClick = (id:any) => {
		setSelectedSemesters(Number(id) === Number(selectedSemesters[0]) ? [] : [id]);

	};
	const handleInstructorShareCheckboxClick=(e: React.ChangeEvent<HTMLInputElement>)=>{
		if (e.target.checked === true) {
			courseDetailsFormik.setFieldValue(COURSES.FORMIK.SHOW_INSTRUCTOR_SHARE, true)
		} else {
			courseDetailsFormik.setFieldValue(COURSES.FORMIK.SHOW_INSTRUCTOR_SHARE, false)

		}

	}

	const handleAddCourse = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		courseDetailsFormik.submitForm();
	};


	const handleImageUpload=(e:any)=>{
		setLoading(true);
		const file=e.target.files[0];
		uploadImage(file).then((res)=>{
			console.log(res);
			courseDetailsFormik.setFieldValue(COURSES.FORMIK.IMAGE, res.data[0])
			setLoading(false)
		})}
useEffect(() => {
	courseDetailsFormik.setFieldValue("departments",selectedDepartments)
  
}, [selectedDepartments])
useEffect(() => {
	courseDetailsFormik.setFieldValue("semesters",selectedSemesters)
  
}, [selectedSemesters])

	const courseDetailsFormik = useFormik({
		initialValues: {
			course: "",
			instructor: 0,
			departments: [],
			university: 0,
			description:"",
			link:"",
			price:0,
			share:0,
			shareUnit:1,
			showInstructorShare: false,
			course_type:1,
			image:"",
			code:"",
			onlinePrice:0,
			inPersonPrice:0,
			discount:0,
			discount_type:2,
			intro_video:null,
			category:0,
			semesters:[]
		},
		enableReinitialize: true,
		onSubmit: (values) => {
			
			const payload: AddCourseParam = {
				instructor_id: Number(values.instructor),
				departments:values.departments,
				university_id: values.university,
				name: values.course,
				isPublished: 1,
				discount:values.discount,
				description:values.description,
				whatsapp_link:values.link,
				course_type: values.course_type,
				code:values.code,
				state_id:1,
				category_id:values.category,
				// semesters:values.semesters,
				discount_type:values.discount_type
				
			};
			if(values.image){
				payload.image=values.image

			}
			if(selectedCourse.editOrAdd === INSTRUCTOR.REDUX.ADD)
			{
				// payload.semesters=semesters;
				payload.university_id=university
			}
			if(selectedCourse.editOrAdd !== INSTRUCTOR.REDUX.ADD)
				{
					//@ts-ignore
					payload.semesters=selectedSemesters;
	
				}
			if(values.showInstructorShare){
				payload.instructor_share=values.share
				payload.instructor_share_type=values.shareUnit


			}
			if(Number(values.course_type)===1)
			{
				payload.online_price=values.price
			}
			else if(Number(values.course_type)===2)
			{
				payload.in_person_price=values.price
					
			}
			else{
				payload.online_price=values.onlinePrice
				payload.in_person_price=values.inPersonPrice

			}
			if (selectedDepartments.length === 0) {
				courseDetailsFormik.setFieldError(
					COURSES.FORMIK.DEPARTMENT,
					YUP.REQUIRED
				);
				return;
			}
			toast.promise(
				selectedCourse.editOrAdd === INSTRUCTOR.REDUX.ADD
					? addCourse(payload,videoFile)
					: editCourse(payload, selectedCourse.id as number,videoFile),
				{
					pending: {
						render() {
							return selectedCourse.editOrAdd === INSTRUCTOR.REDUX.ADD
								? MESSAGES.COURSE.ADD
								: MESSAGES.COURSE.EDIT;
						},
					},
					success: {
						render() {
							courseDetailsFormik.resetForm();
							handleClose();

							return selectedCourse.editOrAdd === INSTRUCTOR.REDUX.ADD
								? MESSAGES.COURSE.ADDED
								: MESSAGES.COURSE.EDITED;
						},
					},
					error: {
						render({data}:any) {
							return data.data.message || "Failed"
							// return selectedUniversity.editOrAdd === INSTRUCTOR.REDUX.ADD
							// 	? MESSAGES.UNIVERSITY.INVALID
							// 	: MESSAGES.UNIVERSITY.NOTEDITED;
						},
					},
				}
			);
		},
	 validationSchema: selectedCourse.editOrAdd === INSTRUCTOR.REDUX.ADD?CourseAddDetailsSchema: CourseDetailsSchema,
	});
	useEffect(() => {
		if(Number(courseDetailsFormik.values.university)!==0){
		commonDepartmentList({university_id:courseDetailsFormik.values.university}).then((res)=>{
			setAllCategory(res.data)
		})
	}
	},[courseDetailsFormik.values.university])

	useEffect(() => {
		if(Number(courseDetailsFormik.values.university)!==0){
			commonDepartmentList({university_id:courseDetailsFormik.values.university}).then((res)=>{
				setAllCategory(res.data)
			})
		}
		if(Number(university)!==0)
		{
			commonDepartmentList({university_id:university}).then((res)=>{
				setAllCategory(res.data)
			})
		}
	}, [showModal])
	

	return (
		<Modal
			show={showModal}
			onHide={() => {
				handleClose();
			}}
		>
			<div className={styles["add-lead-from"]}>
				<Modal.Header closeButton>
					<Modal.Title onClick={()=>console.log(courseDetailsFormik.errors)}>
						{selectedCourse.editOrAdd === INSTRUCTOR.REDUX.EDIT
							? "Edit Course"
							: "Add New Course"}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={handleAddCourse}>
						<div className={styles.fieldStyle}>
							<Form.Group className="from-group" controlId="formFirstName">
								<Form.Label>course</Form.Label>
								<Form.Control
									disabled={loading}
									type="text"
									placeholder="course"
									value={courseDetailsFormik.values.course}
									name={COURSES.FORMIK.COURSE}
									onChange={courseDetailsFormik.handleChange}
								/>
							</Form.Group>
							{courseDetailsFormik.errors.course &&
              courseDetailsFormik.touched.course ? (
									<span className={commonstyles.error}>
										{courseDetailsFormik.errors.course}
									</span>
								) : null}
						</div>	
						<div className={styles.fieldStyle}>
							<Form.Group className="from-group" controlId="formFirstName">
								<Form.Label>code</Form.Label>
								<Form.Control
									disabled={loading}
									type="text"
									placeholder="code"
									value={courseDetailsFormik.values.code}
									name={"code"}
									onChange={courseDetailsFormik.handleChange}
								/>
							</Form.Group>
							{courseDetailsFormik.errors.code &&
              courseDetailsFormik.touched.code ? (
									<span className={commonstyles.error}>
										{courseDetailsFormik.errors.code}
									</span>
								) : null}
						</div>	
						<div className={styles.fieldStyle}>
							<Form.Group className="from-group" controlId="formFirstName">
								<Form.Label>Discount</Form.Label>
								<Form.Control
									disabled={loading}

									type="number"
									placeholder="Discount"
									value={courseDetailsFormik.values.discount}
									name={COURSES.FORMIK.DISCOUNT}
									onChange={courseDetailsFormik.handleChange}
								/>
							</Form.Group>
							{courseDetailsFormik.errors.discount &&
              courseDetailsFormik.touched.discount ? (
									<span className={commonstyles.error}>
										{courseDetailsFormik.errors.discount}
									</span>
								) : null}
						</div>
						<div className={styles.fieldStyle}>

							<Form.Group className="from-group" controlId="formUniversity">
								<Form.Label>Discount Unit</Form.Label>
								<Form.Control
									disabled={loading}

									name={"discount_type"}
									value={Number(courseDetailsFormik.values.discount_type)}
									as="select"
									onChange={courseDetailsFormik.handleChange}
								>
									<option value={2} key={1}>
                    %
									</option>
									<option value={1} key={2}>
                    KD
									</option>
								
								</Form.Control>
							</Form.Group>
								
						</div>	
						<div className={styles.fieldStyle}>
							<Form.Group className="from-group" controlId="formFirstName">
								<Form.Label>Description</Form.Label>
								<Form.Control
									as="textarea" rows={5}
									disabled={loading}
									type="text"
									placeholder="Description"
									value={courseDetailsFormik.values.description}
									name={COURSES.FORMIK.DESCRIPTION}
									onChange={courseDetailsFormik.handleChange}
								/>
							</Form.Group>
							{courseDetailsFormik.errors.description &&
              courseDetailsFormik.touched.description ? (
									<span className={commonstyles.error}>
										{courseDetailsFormik.errors.description}
									</span>
								) : null}
						</div>
						<div className={styles.fieldStyle}>
							<Form.Group className="from-group" controlId="formFirstName">
								<Form.Label>Banner Image</Form.Label>
								<Form.Control
	                         accept="image/*"
									type="file"
									name={COURSES.FORMIK.IMAGE}
									disabled={loading}					
									onChange={handleImageUpload}
									placeholder="No. of members"	
								/>
							
							</Form.Group>
							{courseDetailsFormik.errors.image &&
              courseDetailsFormik.touched.image ? (
									<span className={commonstyles.error}>
										{courseDetailsFormik.errors.image}
									</span>
								) : null}
						</div>
						<div className={styles.fieldStyle}>
							<Form.Group className="from-group" controlId="formFirstName">
								<Form.Label>Intro Video</Form.Label>
								<Form.Control
	                         accept="video/*"
									type="file"
									name={"intro_video"}
									disabled={loading}					
									onChange={handleFileChange}
									placeholder="No. of members"	
								/>
							
							</Form.Group>
							{courseDetailsFormik.errors.image &&
              courseDetailsFormik.touched.image ? (
									<span className={commonstyles.error}>
										{courseDetailsFormik.errors.image}
									</span>
								) : null}
						</div>
						<div className={styles.fieldStyle}>

							<Form.Group className="from-group" controlId="formUniversity">
								<Form.Label>Course Type</Form.Label>
								<Form.Control
									disabled={loading}

									name={COURSES.FORMIK.COURSE_TYPE}
									value={Number(courseDetailsFormik.values.course_type)}
									as="select"
									onChange={courseDetailsFormik.handleChange}
								>
									<option value={1} key={1}>
                    Online
									</option>
									<option value={2} key={2}>
                    In person
									</option>
									<option value={3} key={3}>
                    Both
									</option>
									
								</Form.Control>
							</Form.Group>
								
						</div>	
						
						
						{Number(courseDetailsFormik.values.course_type)===3?<>
							<div className={styles.fieldStyle}>
								<Form.Group className="from-group" controlId="formFirstName">
									<Form.Label>online Price</Form.Label>
									<Form.Control
										disabled={loading}

										type="number"
										placeholder="Online Price"
										value={courseDetailsFormik.values.onlinePrice}
										name={COURSES.FORMIK.ONLINE_PRICE}
										onChange={courseDetailsFormik.handleChange}
									/>
								</Form.Group>
								{courseDetailsFormik.errors.onlinePrice &&
              courseDetailsFormik.touched.onlinePrice ? (
										<span className={commonstyles.error}>
											{courseDetailsFormik.errors.onlinePrice}
										</span>
									) : null}
							</div>
							<div className={styles.fieldStyle}>
								<Form.Group className="from-group" controlId="formFirstName">
									<Form.Label>In Person Price</Form.Label>
									<Form.Control
										disabled={loading}

										type="number"
										placeholder="In Person Price"
										value={courseDetailsFormik.values.inPersonPrice}
										name={COURSES.FORMIK.INPERSON_PRICE}
										onChange={courseDetailsFormik.handleChange}
									/>
								</Form.Group>
								{courseDetailsFormik.errors.inPersonPrice &&
              courseDetailsFormik.touched.inPersonPrice ? (
										<span className={commonstyles.error}>
											{courseDetailsFormik.errors.inPersonPrice}
										</span>
									) : null}
							</div>
						</>:<div className={styles.fieldStyle}>
							<Form.Group className="from-group" controlId="formFirstName">
								<Form.Label>Price</Form.Label>
								<Form.Control
									disabled={loading}

									type="number"
									placeholder="Price"
									value={courseDetailsFormik.values.price}
									name={COURSES.FORMIK.PRICE}
									onChange={courseDetailsFormik.handleChange}
								/>
							</Form.Group>
							{courseDetailsFormik.errors.price &&
              courseDetailsFormik.touched.price ? (
									<span className={commonstyles.error}>
										{courseDetailsFormik.errors.price}
									</span>
								) : null}
						</div>}
						<div className={styles.fieldStyle}>

								
							<Form.Check
								key={1}
								className="from-check"
								disabled={loading}

											
								type="checkbox"
											
								onChange={handleInstructorShareCheckboxClick}
								label={"Add Instructor Share"}
							></Form.Check>
									

							{courseDetailsFormik.errors.share &&
                courseDetailsFormik.touched.share ? (
									<span className={commonstyles.error}>
										{courseDetailsFormik.errors.share}
									</span>
								) : null}
						</div>
						
						
						{courseDetailsFormik.values.showInstructorShare&&<>
							<div className={styles.fieldStyle}>
								<Form.Group className="from-group" controlId="formFirstName">
									<Form.Label>Instructor Share</Form.Label>
									<Form.Control
										type="number"
										disabled={loading}

										placeholder="Instructor Share"
										value={courseDetailsFormik.values.share}
										name={COURSES.FORMIK.SHARE}
										onChange={courseDetailsFormik.handleChange}
									/>
								</Form.Group>
								{courseDetailsFormik.errors.share &&
              courseDetailsFormik.touched.share ? (
										<span className={commonstyles.error}>
											{courseDetailsFormik.errors.share}
										</span>
									) : null}
							</div>	
							<div className={styles.fieldStyle}>

								<Form.Group className="from-group" controlId="formUniversity">
									<Form.Label>Instructor Share Unit</Form.Label>
									<Form.Control
										disabled={loading}

										name={COURSES.FORMIK.SHARE_UNIT}
										value={Number(courseDetailsFormik.values.shareUnit)}
										as="select"
										onChange={courseDetailsFormik.handleChange}
									>
										<option value={1} key={1}>
                    %
										</option>
										<option value={2} key={2}>
                    KD
										</option>
									
									</Form.Control>
								</Form.Group>
								{courseDetailsFormik.errors.shareUnit &&
              courseDetailsFormik.touched.shareUnit ? (
										<span className={commonstyles.error}>
											{courseDetailsFormik.errors.shareUnit}
										</span>
									) : null}
							</div>	
						</>}
						{!(selectedCourse.editOrAdd === INSTRUCTOR.REDUX.ADD)&&<div className={styles.fieldStyle}>

							<Form.Group className="from-group" controlId="formUniversity">
								<Form.Label>University</Form.Label>
								<Form.Control
									disabled={loading}

									name={COURSES.FORMIK.UNIVERSITY}
									value={courseDetailsFormik.values.university}
									as="select"
									onChange={(e) => handleUniversityChange(e)}
								>
									<option value={0} key={0}>
                    Select University
									</option>
									{universities?.map((university, id) => (
										<option value={university.id} key={id}>
											{university.name}
										</option>
									))}
								</Form.Control>
							</Form.Group>
							{courseDetailsFormik.errors.university &&
              courseDetailsFormik.touched.university ? (
									<span className={commonstyles.error}>
										{courseDetailsFormik.errors.university}
									</span>
								) : null}
						</div>}
						<div className={styles.fieldStyle}>
							<Form.Group className="from-group" controlId="formUniversity">
								<Form.Label>Category</Form.Label>
								<Form.Control
									name={"category"}
									value={courseDetailsFormik.values.category}
									as="select"
									onChange={courseDetailsFormik.handleChange}
								>
									<option value={0} key={0}>
                    Select Category
									</option>
									{/* <option value={2} key={1}>
                    Diploma
									</option> */}
									{allCategory.map((cat:any)=>{
										return	<option value={cat.id} key={1}>
                    {cat.name}
									</option>
									})}
									
								</Form.Control>
							</Form.Group>
							{courseDetailsFormik.errors.category &&
              courseDetailsFormik.touched.category ? (
									<span className={commonstyles.error}>
										{courseDetailsFormik.errors.category}
									</span>
								) : null}
						</div>
						<div className={styles.fieldStyle}>

							<Form.Group className="from-group" controlId="formUniversity">
								<Form.Label>Whatsapp Group Link</Form.Label>
								<Form.Control
									disabled={loading}

									type="text"
									placeholder="Link"
									value={courseDetailsFormik.values.link}
									name={COURSES.FORMIK.LINK}
									onChange={courseDetailsFormik.handleChange}
								/>
							</Form.Group>
							{courseDetailsFormik.errors.link &&
courseDetailsFormik.touched.link ? (
									<span className={commonstyles.error}>
										{courseDetailsFormik.errors.link}
									</span>
								) : null}
						</div>

						<div className={styles.fieldStyle}>
							<Form.Group className="from-group" controlId="formUniversity">
								<Form.Label>Instructor</Form.Label>
								<Form.Control
									name={COURSES.FORMIK.INSTRUCTOR}
									value={courseDetailsFormik.values.instructor}
									as="select"
									disabled={loading}

									onChange={courseDetailsFormik.handleChange}
								>
									<option value={0} key={0}>
                    Select Instructor
									</option>
									{instructors?.map((instructor, id) => (
										<option value={instructor.id} key={id}>
											{instructor.full_name}
										</option>
									))}
								</Form.Control>
							</Form.Group>
							{courseDetailsFormik.errors.instructor &&
              courseDetailsFormik.touched.instructor ? (
									<span className={commonstyles.error}>
										{courseDetailsFormik.errors.instructor}
									</span>
								) : null}
						</div>
					
					
						{ (
							<div className={styles.fieldStyle}>
								<Form.Label>Departments</Form.Label>

								{department?.map((res, id) => {
									return (
										<Form.Check
											disabled={loading}

											key={id}
											className="from-check"
											value={res.id as number}
											type="checkbox"
											checked={
												selectedDepartments.includes(res.id as number)
													? true
													: false
											}
											onChange={handleCheckboxClick}
											label={res.name}
										></Form.Check>
									);
								})}

								{courseDetailsFormik.errors.departments &&
                courseDetailsFormik.touched.departments ? (
										<span className={commonstyles.error}>
											{courseDetailsFormik.errors.departments}
										</span>
									) : null}
							</div>
						)}
							{courseDetailsFormik.values.university > 0 && !(selectedCourse.editOrAdd === INSTRUCTOR.REDUX.ADD)&& (
							<div className={styles.fieldStyle}>
								<Form.Label>Semester</Form.Label>

								{semester?.map((res, id) => {
									return (
										<Form.Check
											disabled={loading}

											key={id}
											className="from-check"
											value={res.id as number}
											type="checkbox"
											checked={
												selectedSemesters[0] === res.id
											}
											onChange={()=>handleSemesterCheckboxClick(res.id)}
											label={`${res.name} (${moment(res.start_date).format("MMM DD, YYYY")}-${moment(res.end_date).format("MMM DD, YYYY")})`}
										></Form.Check>
									);
								})}

								{/* <Form.Control
									disabled={loading}

									name={"semester_id"}
									value={courseDetailsFormik.values.semester_id}
									as="select"
									onChange={courseDetailsFormik.handleChange}
								>
									<option value={0} key={0}>
                    Select Semester
									</option>
									{semester?.map((sem, id) => (
										<option value={sem.id} key={id}>
											{moment(sem.start_date).format("MMM DD, YYYY")}-{moment(sem.end_date).format("MMM DD, YYYY")}  
										</option>
									))}
								</Form.Control> */}

								{courseDetailsFormik.errors.semesters &&
                courseDetailsFormik.touched.semesters ? (
										<span className={commonstyles.error}>
											{courseDetailsFormik.errors.semesters}
										</span>
									) : null}
							</div>
						)}

						{/* <Button variant="outline-secondary" className="mb-2">
                    <i className="fas fa-plus"></i> Choose More Department
                </Button> */}
						<hr />
						{/* <Button variant="outline-secondary" className="mb-2">
                    <i className="fas fa-plus"></i> Add More University
                </Button> */}
						<Button className={`${styles.buttonss} mt-3`} type="submit">
							{selectedCourse.editOrAdd === INSTRUCTOR.REDUX.ADD
								? INSTRUCTOR.ADD
								: INSTRUCTOR.EDIT}
						</Button>
					</Form>
				</Modal.Body>
			</div>
		</Modal>
	);
};

export default AddorEdit;
