import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Button, Dropdown, Form, InputGroup, Spinner, Toast } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faEdit,
  faEllipsisH,
  faInfoCircle,
  faPlus,
  faSearch,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

import { courseList, editCourseStatus, removeCourse } from "../api";
import { Avatar } from "../../../assets/images"
import { CourseDeatails } from "../api/interface";
import Layout from "../../../components/Layout";
import commonStyles from "../../common/styles/common.module.css";
import Pagination from "../../../components/Pagination";
import { PaginationInterface } from "../../../components/Pagination/interfaces";
import { PriceModal } from "../modals";
import { courseModal } from "../../../redux/reducers/course";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { MESSAGES } from "../../../utils/messages";
import { ROUTE_CONSTANTS } from "../../../routes/constants";
import { INSTRUCTOR } from "../../../utils/instructor";
import AddorEdit from "../modals/AddorEdit";
import { APP_ROUTES } from "../../../utils/routes";
import MultiSelect, { Option } from "./MultiSelect";
import { departmentList, getSemesters, universityList } from "../../university/api";

const CourseList = () => {
  const dispatch = useAppDispatch();
  const [optionSelected, setSelected] = useState<any>([]);
  const handleChange = (selected: any) => {
    setSelected(selected);
  };

  const [course, setCourse] = useState<CourseDeatails[]>([]);
  const [priceModal, setPriceModal] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [search, setSearch] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [universities, setUniversities] = useState<any>([]);
  const [semesters, setSemesters] = useState<any>([]);
  const [departments, setDepartments] = useState<any>([]);
  const [departmentFilter, setDepartmentFilter] = useState<any>(0);


  const [universityFilter, setUniversityFilter] = useState<number>(0);
  const [semesterFilter, setSemesterFilter] = useState<any>([]);

  const [modeFilter, setModeFilter] = useState<number>(0);





  const handleSearch = () => {
    setSearchValue(search);
  };
  useEffect(() => {
   universityList({}).then((res)=>{
    setUniversities(res.data);
   })
  }, [])

  useEffect(() => {
    if(Number(universityFilter)!==0)
    {
    getSemesters({},Number(universityFilter)).then((res)=>{
    //  setSemesters(res.data);
    const arr:any=[];
    res.data.map((sem:any)=>{
      arr.push({value:sem.id,label:`${sem.name} (${moment(sem.start_date).format("MMM DD, YYYY")}-${moment(sem.end_date).format("MMM DD, YYYY")})`})
    })
    setSemesters(arr)

     

    })
    const id=universities.findIndex((uni:any)=>{
      return Number(uni.id)===Number(universityFilter)
    })
    setDepartments((universities[id].universityDepartments)
  )
  // console.log((universities[id].universityDepartments),"iddd")
  
  }
    else{
      setSemesters([])
      setDepartments([])
      
    }
   }, [universityFilter])
  

  const searchText = useAppSelector((state) => state.searchReducer.text);

  useEffect(() => {
    setSearchValue(searchText as string);
  }, [searchText]);

  const navigate = useNavigate();
  // const [showModal, setShowModal] = useState<boolean>(false)
  const [pagination, setPagination] = useState<PaginationInterface>({
    page: 1,
    limit: 10,
    count: 0,
    totalPages: 0,
  });
  const addNewCourse = () => {
    if(Number(universityFilter)){
    dispatch(courseModal({ id: 0, editOrAdd: INSTRUCTOR.REDUX.ADD }));
    setShowModal(true);}
    else{
      toast.error("Please Select University")
    }
  };
  const changeToggleStatus = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    const currentStatus = !e.target.checked;
    console.log(currentStatus);
    e.preventDefault();

    toast.promise(
      editCourseStatus(!currentStatus === true ? 1 : 0, id as number),
      {
        pending: {
          render() {
            e.target.checked = currentStatus;

            return MESSAGES.COURSE.EDIT;
          },
        },
        success: {
          render() {
            e.target.checked = !currentStatus;

            return MESSAGES.COURSE.EDITED;
          },
        },
        error: {
          render() {
            e.target.checked = currentStatus;
            return MESSAGES.COURSE.NOTEDITED;
          },
        },
      }
    );
  };
  const refresh = () => {
    setLoading(true);
    const query = {
      per_page: pagination.limit,
      page: pagination.page,
      name: searchValue,
      
    };

    courseList(query,universityFilter,undefined,undefined,undefined,modeFilter,undefined,optionSelected?.map((obj:any) => obj.value).join(',')).then((res) => {
      const {
        paginate: { total, last_page },
        data,
      } = res;
      setPagination({ ...pagination, count: total, totalPages: last_page });
      setCourse(data);
      setLoading(false);
    });
  };

  const handleRemoveCourse = (id: number) => {
    toast.promise(removeCourse(id), {
      pending: {
        render() {
          return MESSAGES.COURSE.REMOVE;
        },
      },
      success: {
        render({}) {
          refresh();

          return MESSAGES.COURSE.REMOVED;
        },
      },
      error: {
        render() {
          return MESSAGES.COURSE.NOTREMOVE;
        },
      },
    });
  };
  const handleClose = () => {
    setPriceModal(false);
  };
  const handleAddClose = () => {
    setShowModal(false);
  };

  const approveCourse = (id: number) => {
    dispatch(courseModal({ id }));
    setPriceModal(true);
  };

  useEffect(() => {
    refresh();
  }, [pagination.page, searchValue,universityFilter,modeFilter,optionSelected]);

  useEffect(() => {
    if (showModal === false) refresh();
  }, [priceModal, showModal]);

  return (
    <Layout>
      <div className={commonStyles["outtre-pad"]}>
        <div className={commonStyles["heaing-filter"]}>
          <h3>Course Management</h3>
          <div className={commonStyles["filter-left"]}>
        
            <span>
              University:{" "}
              <select onChange={(e)=>{setUniversityFilter(Number(e.target.value))}}>
                <option value={0}>Select</option>
                {universities.map((res:any)=>{
                  return <option value={res.id}>{res.name}</option>
                })}
              </select>
            </span>
            {/* <span>
              Departments:{" "}
              <select 
               onChange={(e)=>{
                // var na=[]
                // for(var i=0;i<e.target.selectedOptions.length;i++)
                // {
                //   na.push(e.target.selectedOptions[i].value)
                // }
                ;setDepartmentFilter(Number(e.target.value))}}
               >
                <option value={0}>Select</option>
                {departments.map((res:any)=>{
                  return <option value={res.id}>{res.name}</option>
                })}
              </select>
            </span> */}
            <span>
              Semester:{" "}
              <MultiSelect
        key="example_id"
        options={semesters.length?semesters:[]}
        onChange={handleChange}
        value={optionSelected}
        isSelectAll={true}
        menuPlacement={"top"}
        
      />
            </span>
            <span>
              Course Mode:{" "}
              <select onChange={(e)=>{setModeFilter(Number(e.target.value))}}>
              <option value={0}>Select</option>
                <option value={1}>Online</option>
                <option value={2}>In-person</option>
                <option value={3} >Both</option>

              </select>
            </span>
            {/* <span>
              Filter:{" "}
              <select>
                <option>Department</option>
                <option>This Week</option>
              </select>
            </span>
            <span>
              Filter:{" "}
              <select>
                <option>This Week</option>
                <option>This Week</option>
              </select>
            </span> */}
          </div>
        </div>
        {!loading ? (
          <div className={`light-theme-table ${commonStyles["table-data"]}`}>
            <div className={`${commonStyles["property-head"]} pb-1`}>
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                {/* <InputGroup className={`${commonStyles["search-bar"]} mb-3`}>
                  <Form.Control
                    placeholder="Search Name"
                    type="text"
                    value={search}
                    aria-label="Search"
                    onChange={(e) => setSearch(e.target.value)}
                    aria-describedby="basic-addon2"
                  />
                  <Button
                    onClick={handleSearch}
                    variant="outline-secondary"
                    id="button-addon2"
                  >
                    <FontAwesomeIcon icon={faSearch} />
                  </Button>
                </InputGroup> */}
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${commonStyles.activeTab}`}
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    All
                  </button>
                </li>
              </ul>

              <div className={commonStyles["filter-pro"]}>
                <button
                  onClick={addNewCourse}
                  className={commonStyles["add-lead"]}
                >
                  <FontAwesomeIcon icon={faPlus} /> Add New Course
                </button>
                {/* <button
                  onClick={() => {
                    navigate(APP_ROUTES.ADDON_LIST);
                  }}
                  className={commonStyles["add-lead"]}
                >
                  Add-Ons
                </button> */}
                {/* <button
                  onClick={() => {
                    navigate(
                      `${"/universities/commondepartments"}`
                    )
                  }}
                  className={commonStyles["add-lead"]}
                >
                  <FontAwesomeIcon icon={faPlus} /> Add Category
                </button> */}
              </div>
            </div>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div style={{ overflowX: "auto" }}>
                  <table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Course Mode</th>
                        <th>Code</th>      
                        <th className="text-center">Instructor</th>
                        {/* <th>Published</th>
                        <th className="text-center">Created At</th> */}
                        <th>Online Price</th>
                        <th>In-Person Price</th>

                        <th className="text-center">University</th>
                        {/* <th>Status</th> */}
                        <th>Category</th>
                        <th>Add Ons</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {course.map((item) => {
                        return (
                          <tr key={item.id}>
                            
                            <td>
                              <img 
  src={item.image ? item.image : Avatar}
                              height={40}
                              width={40}/>
                              {item.name}</td>
                            <td className="text-center">
                              {item?.course_type_name}
                            </td>
                            <td >
                              {item?.code}
                            </td>
                            <td className="text-center">
                              {item?.instructor?.full_name}
                            </td>
                         
                           
                            {/* <td>
                              {item.isPublished ? "Published" : "Not Published"}{" "}
                            </td>
                            <td className="text-center">
                              {moment(item?.created_at).format("MMM DD, YYYY")}
                            </td> */}
                            <td className="text-center">
                              {item?.course_type!==2?<><s style={{marginRight:"5px"}}>{item.online_price}</s>{item.online_discounted_price} KD</>:"Not Applicable"}
                            </td>
                            <td className="text-center">
                              {item?.course_type!==1?<><s style={{marginRight:"5px"}}>{item.in_person_price}</s>{item.in_person_discounted_price} KD</>:"Not Applicable"}
                            </td>
                            <td className="text-center">
                              {item?.university?.name}
                            </td>
                            <td>
                              {/* <span
                                className={
                                  !(
                                    item.is_approved_by_admin === 1 &&
                                    item.is_approved_by_instructor === 1
                                  )
                                    ? `${commonStyles["bl-shd"]} ${commonStyles.red}`
                                    : `${commonStyles["bl-shd"]} ${commonStyles.grn}`
                                }
                              >
                                {item.is_approved_by_admin === 1 &&
                                item.is_approved_by_instructor === 1
                                  ? "Approved"
                                  : "Not Approved"}
                              </span> */}
                              {//@ts-ignore
                              item?.categories?.name}
                            </td>
                            <td className="text-center">
                            {item?.adson?.map((obj:any) => obj?.name).join(', ')}
                          </td>

                            <td className="text-center">
                              <div className="form-check form-switch d-flex align-items-center">
                                <input
                                  onChange={(e) =>
                                    changeToggleStatus(e, item.id)
                                  }
                                  defaultChecked={
                                    item.state_id === 1 ? true : false
                                  }
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                />
                                <Dropdown
                                  drop={"down-centered"}
                                  className={commonStyles.tddropdown}
                                >
                                  <Dropdown.Toggle>
                                    <a className="me-2"></a>
                                    <FontAwesomeIcon icon={faEllipsisH} />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      onClick={() =>
                                        navigate(
                                          `${ROUTE_CONSTANTS.COURSES}/${item.id}`
                                        )
                                      }
                                    >
                                      <FontAwesomeIcon
                                        size="xs"
                                        className={commonStyles.iconmargin}
                                        icon={faInfoCircle}
                                      />
                                      View More
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() => {
                                        dispatch(
                                          courseModal({
                                            id: item.id,
                                            editOrAdd: INSTRUCTOR.REDUX.EDIT,
                                          })
                                        );
                                        setShowModal(true);
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        // onClick={() => {
                                        //   dispatch(
                                        //     courseModal({
                                        //       id: item.id,
                                        //       editOrAdd: INSTRUCTOR.REDUX.EDIT,
                                        //     })
                                        //   );
                                        //   setShowModal(true);
                                        // }}
                                        size="xs"
                                        className={commonStyles.iconmargin}
                                        icon={faEdit}
                                      />
                                      Edit
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() => {
                                        navigate(`${APP_ROUTES.ADDON_LIST}/${item.id}`);
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        size="xs"
                                        className={commonStyles.iconmargin}
                                        icon={faEdit}
                                      />
                                      Add-Ons
                                    </Dropdown.Item>

                                    <Dropdown.Item
                                      onClick={() => approveCourse(item.id)}
                                    >
                                      <FontAwesomeIcon
                                        size="xs"
                                        className={commonStyles.iconmargin}
                                        icon={faCheck}
                                      />
                                      Approve
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() =>
                                        handleRemoveCourse(item.id)
                                      }
                                    >
                                      <FontAwesomeIcon
                                        size="xs"
                                        className={commonStyles.iconmargin}
                                        icon={faTrash}
                                      />
                                      Remove
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" role="status"></Spinner>
          </div>
        )}
      </div>

      <Pagination
        paginationState={pagination}
        setPaginationState={setPagination}
      />
      <PriceModal handleClose={handleClose} showModal={priceModal} />
      <AddorEdit showModal={showModal} handleClose={handleAddClose}  university={universityFilter} />
    </Layout>
  );
};
export default CourseList;
