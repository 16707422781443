import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Button, Form, Modal } from "react-bootstrap";

import { AddUniversityProp } from "./interface";
import { useAppSelector } from "../../../redux/hooks";
import { INSTRUCTOR } from "../../../utils/instructor";
import styles from "../../instructor/styles/instructor.module.css";
import commonstyles from "../../instructor/styles/common.module.css";
import { addUniversity, editUniversity, getUniversity } from "../api";
import { DepartmentDetailsSchema, UniversityDetailsSchema } from "../validations";
import { UNIVERSITY } from "../../../utils/university";
import { MESSAGES } from "../../../utils/messages";
import { uploadImage } from "../../common/utils";
import AddImage from "./UploadImageModal";

const AddOrEdit = ({ showModal, handleClose }: AddUniversityProp) => {
  const selectedUniversity = useAppSelector((state) => state.universityReducer);

  const [croppedImage, setCroppedImage] = useState<File | null>(null);

  const [showModalImage, setShowModalImage] = useState<boolean>(false);
  const handleCloseImage = () => {
    setShowModalImage(false);
  };

  useEffect(() => {
    universityDetailsFormik.resetForm();
    setCroppedImage(null);
    if (selectedUniversity.editOrAdd === INSTRUCTOR.REDUX.EDIT) {
      getUniversity(Number(selectedUniversity.id)).then((res) => {
        const {
          data: { name, max_installment},
        } = res;
        universityDetailsFormik.setFieldValue(UNIVERSITY.FORMIK.NAME, name);
        universityDetailsFormik.setFieldValue("max_installment", max_installment);

      });
    } else {
      universityDetailsFormik.resetForm();
    }
  }, [showModal]);

  const universityDetailsFormik = useFormik({
    initialValues: {
      name: "",
      image: croppedImage,
      max_installment: ''
    },
    enableReinitialize: true,
    onSubmit: (values) => {
     
      uploadImage(values.image).then((res) => {
        toast.promise(
          selectedUniversity.editOrAdd === INSTRUCTOR.REDUX.ADD
            ? addUniversity(values.max_installment,values.name, res.data[0])
            : editUniversity(values.max_installment,selectedUniversity.id, values.name, res.data[0]),
          {
            pending: {
              render() {
                return selectedUniversity.editOrAdd === INSTRUCTOR.REDUX.ADD
                  ? MESSAGES.UNIVERSITY.ADD
                  : MESSAGES.UNIVERSITY.EDIT;
              },
            },
            success: {
              render() {
                universityDetailsFormik.resetForm();
                handleClose();

                return selectedUniversity.editOrAdd === INSTRUCTOR.REDUX.ADD
                  ? MESSAGES.UNIVERSITY.SUCCESS
                  : MESSAGES.UNIVERSITY.EDITED;
              },
            },
            error: {
              render() {
                return selectedUniversity.editOrAdd === INSTRUCTOR.REDUX.ADD
                  ? MESSAGES.UNIVERSITY.INVALID
                  : MESSAGES.UNIVERSITY.NOTEDITED;
              },
            },
          }
        );
      }).catch(()=>{
        toast.promise(
          selectedUniversity.editOrAdd === INSTRUCTOR.REDUX.ADD
            ? addUniversity(values.max_installment,values.name)
            : editUniversity(values.max_installment,selectedUniversity.id, values.name),
          {
            pending: {
              render() {
                return selectedUniversity.editOrAdd === INSTRUCTOR.REDUX.ADD
                  ? MESSAGES.UNIVERSITY.ADD
                  : MESSAGES.UNIVERSITY.EDIT;
              },
            },
            success: {
              render() {
                universityDetailsFormik.resetForm();
                handleClose();

                return selectedUniversity.editOrAdd === INSTRUCTOR.REDUX.ADD
                  ? MESSAGES.UNIVERSITY.SUCCESS
                  : MESSAGES.UNIVERSITY.EDITED;
              },
            },
            error: {
              render() {
                return selectedUniversity.editOrAdd === INSTRUCTOR.REDUX.ADD
                  ? MESSAGES.UNIVERSITY.INVALID
                  : MESSAGES.UNIVERSITY.NOTEDITED;
              },
            },
          }
        );
      });
    },

    validationSchema: UniversityDetailsSchema,
  });
  const handleAddDepartment = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    universityDetailsFormik.submitForm();
  };

  return (
    <Modal
      show={showModal}
      onHide={() => {
        handleClose();
      }}
    >
      <div>
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedUniversity.editOrAdd === INSTRUCTOR.REDUX.EDIT
              ? "Edit University"
              : "Add New University"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAddDepartment}>
            <div className={styles.fieldStyle}>
              <Form.Group className="from-group" controlId="formFirstName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Name"
                  value={universityDetailsFormik.values.name}
                  name="name"
                  onChange={universityDetailsFormik.handleChange}
                />
              </Form.Group>
              {universityDetailsFormik.errors.name &&
              universityDetailsFormik.touched.name ? (
                <span className={commonstyles.error}>
                  {universityDetailsFormik.errors.name}
                </span>
              ) : null}
              <Form.Group className="from-group" controlId="formFirstName">
                <Form.Label>Logo</Form.Label>
                <Button
                  onClick={() => setShowModalImage(true)}
                  className={`${styles.buttonss} mt-3`}
                >
                  {croppedImage ? "Update Image" : "Upload Image"}
                </Button>
              </Form.Group>
              {/* {universityDetailsFormik.errors.name &&
              universityDetailsFormik.touched.name ? (
									<span className={commonstyles.error}>
										{universityDetailsFormik.errors.name}
									</span>
								) : null} */}
            </div>
            <div className={styles.fieldStyle}>
              <Form.Group className="from-group" controlId="formFirstName">
                <Form.Label>Max Installments Limit(In Months)</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Months"
                  value={universityDetailsFormik.values.max_installment}
                  name="max_installment"
                  onChange={universityDetailsFormik.handleChange}
                />
              </Form.Group>
              {universityDetailsFormik.errors.max_installment &&
              universityDetailsFormik.touched.max_installment ? (
                <span className={commonstyles.error}>
                  {universityDetailsFormik.errors.max_installment}
                </span>
              ) : null}
            
              {/* {universityDetailsFormik.errors.name &&
              universityDetailsFormik.touched.name ? (
									<span className={commonstyles.error}>
										{universityDetailsFormik.errors.name}
									</span>
								) : null} */}
            </div>
            <Button className={`${styles.buttonss} mt-3`} type="submit">
              {selectedUniversity.editOrAdd === INSTRUCTOR.REDUX.ADD
                ? INSTRUCTOR.ADD
                : INSTRUCTOR.EDIT}
            </Button>
          </Form>
        </Modal.Body>
        <AddImage
          croppedImage={croppedImage}
          setCroppedImage={setCroppedImage}
          showModal={showModalImage}
          handleClose={handleCloseImage}
        />
      </div>
    </Modal>
  );
};

export default AddOrEdit;
