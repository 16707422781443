import React from "react";
// import { useFormik } from "formik";
// import { toast } from "react-toastify";
import { Button, Form, Modal } from "react-bootstrap";

import { AddUniversityProp } from "./interface";
// import { useAppSelector } from "../../../redux/hooks";
// import { INSTRUCTOR } from "../../../utils/instructor";
import styles from "../../instructor/styles/instructor.module.css";
 import commonstyles from "../../instructor/styles/common.module.css";
// import { addUniversity, editUniversity, getUniversity } from "../api";
// import DepartmentDetailsSchema from "../validations";
import { UNIVERSITY } from "../../../utils/university";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { addSemester } from "../api";
import { SemesterSchema } from "../validations";
// import { MESSAGES } from "../../../utils/messages";

const AddOrEditSemester = ({ showModal, handleClose }: AddUniversityProp) => {
	const {id}=useParams()
	// const selectedUniversity = useAppSelector((state) => state.universityReducer);

	// useEffect(() => {
	// 	if (selectedUniversity.editOrAdd === INSTRUCTOR.REDUX.EDIT) {
	// 		getUniversity(Number(selectedUniversity.id)).then((res) => {
	// 			const {
	// 				data: { name },
	// 			} = res;
	// 			universityDetailsFormik.setFieldValue(UNIVERSITY.FORMIK.NAME, name);
	// 		});
	// 	} else {
	// 		universityDetailsFormik.resetForm();
	// 	}
	// }, [showModal]);

	const universityDetailsFormik = useFormik({
		initialValues: {
			name:"",
			start_date: "",
			end_date:""
		},
		enableReinitialize: true,
		onSubmit: (values) => {
			toast.promise(
				addSemester({...values,university_id:id}),
				// selectedUniversity.editOrAdd === INSTRUCTOR.REDUX.ADD
				// 	? addUniversity(values.name)
				// 	: editUniversity(selectedUniversity.id, values.name),
				{
					pending: {
						render() {
							return "Adding"
							// return selectedUniversity.editOrAdd === INSTRUCTOR.REDUX.ADD
							// 	? MESSAGES.UNIVERSITY.ADD
							// 	: MESSAGES.UNIVERSITY.EDIT;
						},
					},
					success: {
						render() {
							universityDetailsFormik.resetForm();
							handleClose();
							return "Added"

							// return selectedUniversity.editOrAdd === INSTRUCTOR.REDUX.ADD
							// 	? MESSAGES.UNIVERSITY.SUCCESS
							// 	: MESSAGES.UNIVERSITY.EDITED;
						},
					},
					error: {
						render({data}:any) {
							return data.data.message || "Failed"
							// return selectedUniversity.editOrAdd === INSTRUCTOR.REDUX.ADD
							// 	? MESSAGES.UNIVERSITY.INVALID
							// 	: MESSAGES.UNIVERSITY.NOTEDITED;
						},
					},
				}
			);
		},

	 validationSchema: SemesterSchema,
	});
	// const handleAddDepartment = (e: React.SyntheticEvent<HTMLFormElement>) => {
	// 	e.preventDefault();

	// 	universityDetailsFormik.submitForm();
	// };

	return (
		<Modal
			show={showModal}
			onHide={() => {
				handleClose();
			}}
		>
			<div>
				<Modal.Header closeButton>
					<Modal.Title>
						{/* {selectedUniversity.editOrAdd === INSTRUCTOR.REDUX.EDIT
							? "Edit University"
							: "Add New University"} */}
                            Add Semester Date
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form >
						<div className={styles.fieldStyle}>
						<Form.Group className="from-group" controlId="formFirstName">
								<Form.Label>Name</Form.Label>
								<Form.Control
									type="text"
									placeholder="Name"
									
									name={"name"}
									onChange={universityDetailsFormik.handleChange}

									
								/>
							</Form.Group>
							{universityDetailsFormik.errors.name &&
              universityDetailsFormik.touched.name ? (
									<span className={commonstyles.error}>
										{universityDetailsFormik.errors.name}
									</span>
								) : null}
							<Form.Group className="from-group" controlId="formFirstName">
								<Form.Label>Start Date</Form.Label>
								<Form.Control
									type="date"
									placeholder="Name"
									
									name={"start_date"}
									onChange={universityDetailsFormik.handleChange}

									
								/>
							</Form.Group>
							{universityDetailsFormik.errors.start_date &&
              universityDetailsFormik.touched.start_date ? (
									<span className={commonstyles.error}>
										{universityDetailsFormik.errors.start_date}
									</span>
								) : null}
							<Form.Group className="from-group" controlId="formFirstName">
								<Form.Label>End Date</Form.Label>
								<Form.Control
									type="date"
									onChange={universityDetailsFormik.handleChange}

									name="end_date"
									
								/>
							</Form.Group>
							{universityDetailsFormik.errors.end_date &&
              universityDetailsFormik.touched.end_date ? (
									<span className={commonstyles.error}>
										{universityDetailsFormik.errors.end_date}
									</span>
								) : null}
						</div>
						<Button onClick={()=>universityDetailsFormik.submitForm()} className={`${styles.buttonss} mt-3`} >
							{/* {selectedUniversity.editOrAdd === INSTRUCTOR.REDUX.ADD
								? INSTRUCTOR.ADD
								: INSTRUCTOR.EDIT} */}
                                Add
						</Button>
					</Form>
				</Modal.Body>
			</div>
		</Modal>
	);
};

export default AddOrEditSemester;
