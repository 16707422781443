import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import {  useNavigate, useParams } from "react-router-dom";
import { Dropdown, Spinner } from "react-bootstrap";
import DraggableList from "react-draggable-list";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faEdit,
	faEllipsisH,
	faEye,
	faGrip,
	faPlus,
	faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

import Layout from "../../../../components/Layout/index";
import commonStyles from "../../../common/styles/common.module.css";
import { commonDepartmentList, departmentList, removeCategory, removeDepartment } from "../../api";
import Pagination from "../../../../components/Pagination";
import { PaginationInterface } from "../../../../components/Pagination/interfaces";
import { AddOrEditDepartment } from "../../modals";
import { INSTRUCTOR } from "../../../../utils/instructor";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { departmentModal } from "../../../../redux/reducers/department";
import { MESSAGES } from "../../../../utils/messages";
import { Department as DepartmentInterface } from "../../api/interface";
import AddOrEditCommonDepartment from "../../modals/AddOrEditComonDepartment";
import { Avatar } from "../../../../assets/images";
import { useDispatch } from "react-redux";
import zIndex from "@mui/material/styles/zIndex";

interface ListItem {
    id: number;
  }
interface ItemProps {
    item: ListItem;
    itemSelected?: boolean; // Optional, adjust based on your usage
    dragHandleProps: {
      onMouseDown: (e: React.MouseEvent) => void;
      onTouchStart: (e: React.TouchEvent) => void;
    };
  }
const Item: React.FC<any> = ({ item, itemSelected, dragHandleProps,setShowModal,handleRemoveDepartment }:any) => {
    const { onMouseDown, onTouchStart } = dragHandleProps;
    const dispatch=useDispatch()
	const navigate=useNavigate()
	const { id } = useParams();

  
    return (
        <tr style={{display:"flex",width:"100%",justifyContent:"space-around"}}   key={item.id}>
											<td style={{width:"250px",textAlign:"center"}} >
                                            <img
                              src={item.icon ? item.icon : Avatar}
                              height={40}
                              width={40}
                              alt="avatar"
                            />{item.name}</td>

												
												

												
												<td  style={{width:"250px",textAlign:"center"}} >
													<div><Dropdown
														drop={"down-centered"}
														className={commonStyles.tddropdown}
													>
														<Dropdown.Toggle>
															<FontAwesomeIcon icon={faEllipsisH} />
														</Dropdown.Toggle>

														<Dropdown.Menu
														className="dm"
														>
															<Dropdown.Item
																onClick={() => {
																	dispatch(
																		departmentModal({
																			id: item.id,
																			editOrAdd: INSTRUCTOR.REDUX.EDIT,
																		})
																	);
																	setShowModal(true);
																}}
															>
																<FontAwesomeIcon
																	className={commonStyles.iconmargin}
																	icon={faEdit}
																	size="xs"
																/>
                                Edit
															</Dropdown.Item>
															<Dropdown.Item
																onClick={() => handleRemoveDepartment(item.id)}
															>
																<FontAwesomeIcon
																	className={commonStyles.iconmargin}
																	size="xs"
																	icon={faTrash}
																/>
                                Remove
															</Dropdown.Item>
															<Dropdown.Item
																 onClick={() => navigate(`/universities/commondepartments/courses/${item.id}/${id}`)}
															>
																<FontAwesomeIcon
																	className={commonStyles.iconmargin}
																	size="xs"
																	icon={faEye}
																/>
                                View Courses
															</Dropdown.Item>
															{/* <Dropdown.Item
																onClick={() => navigate("/universities/semesters")}
															>
																<FontAwesomeIcon
																	className={commonStyles.iconmargin}
																	size="xs"
																	icon={faEye}
																/>
                               View Semesters
															</Dropdown.Item> */}
														
														</Dropdown.Menu>
													</Dropdown>
													</div>
												</td>
                                                <td style={{width:"250px",textAlign:"center"}}>
                                                <div
          className="disable-select dragHandle"
          style={{
         
            
          }}
          onTouchStart={(e) => {
            e.preventDefault();
            console.log("touchStart");
            // e.target.style.backgroundColor = "blue";
             document.body.style.overflow = "hidden";
            onTouchStart(e);
          }}
          onMouseDown={(e) => {
            console.log("mouseDown");
            document.body.style.overflow = "hidden";
            onMouseDown(e);
          }}
          onTouchEnd={(e) => {
            // e.target.style.backgroundColor = "black";
            document.body.style.overflow = "visible";
          }}
          onMouseUp={() => {
             document.body.style.overflow = "visible";
          }}
        ><FontAwesomeIcon icon={faGrip} /></div>
                                                </td>
											</tr>
      
    );
  };
  export {Item}