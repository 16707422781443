import InstructorList from "../features/instructor/routes/list";
import InstructorsCoursesList from "../features/instructor/routes/Courses/routes/list"
import StudentList from "../features/student/routes/list";
import InstructorStudentList from "../features/instructor/routes/Courses/routes/StudentList/list";
import CourseList from "../features/course/routes/list";
import Chapter from "../features/course/routes/chapter/list";
import InstructorChapter from "../features/instructor/routes/Courses/routes/chapter/list";
import ChapterInfo from "../features/instructor/routes/Courses/routes/chapter/ChapterInfo";
import UniversityList from "../features/university/routes/list";
import Department from "../features/university/routes/Departments/list"
import { APP_ROUTES } from "../utils/routes";
import StudentDetails from "../features/student/routes/StudentDetails";
import { AddInstructor } from "../features/instructor/routes/AddOrEdit";
import { AddOrEdit } from "../features/student/modals";
import { Profile } from "../features/auth/routes/Profile";
import CouponsList from "../features/coupons/routes/list";
// import TestVideo from "../features/testVideo/routes";
import InvoiceList from "../features/invoices/routes/list";
import SegmentList from "../features/coupons/routes/Segments/routes/list";
import Installments from "../features/student/routes/InstalmentDetails/routes/list";
import Transactions from "../features/transactions/routes/list";
import Semesters from "../features/university/routes/Departments/Semesters/list";
import Addon from "../features/course/routes/Addons/list";
import test from "../features/university/routes/test";
import UniversityStudent from "../features/university/routes/Students/list";
import UniversityCourses from "../features/university/routes/Courses/list";
import UniversityDepartmentCourses from "../features/university/routes/Departments/Courses/list";
import UniversityDepartmentStudent from "../features/university/routes/Departments/Students/list";
import UniversityCoursesStudent from "../features/university/routes/Courses/Students/list";
import UniversityStory from "../features/university/routes/Story/list";
import CommonDepartment from "../features/university/routes/CommonDepartments/list";
import SegmentUniversityList from "../features/coupons/routes/Segments/routes/University/list";
import ConfigurationsList from "../features/Configurations/routes/list";
import UniversityBanners from "../features/university/routes/Banners/list";
import UniversitySemesters from "../features/university/routes/Semesters/list";
import CategoryCourses from "../features/university/routes/CommonDepartments/Courses/list";


export const protectedRoutes = [
	{path: APP_ROUTES.INSTRUCTORS_LIST, element: InstructorList },
	{path: APP_ROUTES.STUDENTS_LIST, element: StudentList },
	{path: APP_ROUTES.COURSES_LIST, element: CourseList },
	{path: `${APP_ROUTES.ADDON_LIST}/:id`, element: Addon },
	{path: APP_ROUTES.SPECIFIC_COURSE, element: Chapter },
	{path: APP_ROUTES.UNIVERSITY_LIST, element:UniversityList},
	{path: "/universities/students/:id", element:UniversityStudent},
	{path: "/universities/story/:id", element:UniversityStory},
	{path: "/universities/banner/:id", element:UniversityBanners},
	{path: "/universities/semesters/:id", element:UniversitySemesters},
	{path: "/universities/commondepartments/:id", element:CommonDepartment},
	{path: "/universities/commondepartments/courses/:id/:uid", element:CategoryCourses},
	{path: "/universities/courses/:id", element:UniversityCourses},
	{path: "/universities/courses/students/:id", element:UniversityCoursesStudent},
	{path:APP_ROUTES.SPECIFIC_UNIVERSITY, element:Department},
	{path: "/universities/departments/courses/:id", element:UniversityDepartmentCourses},
	{path:APP_ROUTES.SEMESTERS, element:Semesters},
	{path:APP_ROUTES.SPECIFIC_STUDENT, element:StudentDetails},
	{path:APP_ROUTES.STUDENTS_EDIT, element:AddOrEdit},
	{path:APP_ROUTES.STUDENTS_ADD, element:AddOrEdit},
	{path:APP_ROUTES.INSTRUCTORS_ADD, element:AddInstructor},
	{path:APP_ROUTES.INSTRUCTORS_EDIT, element:AddInstructor},
	{path:APP_ROUTES.INSTRUCTORS_COURSES, element:InstructorsCoursesList},
	{path:APP_ROUTES.INSTRUCTORS_COURSES_STUDENTLIST, element:InstructorStudentList},
	{path:APP_ROUTES.INSTRUCTORS_CHAPTERS, element:InstructorChapter},
	{path:APP_ROUTES.INSTRUCTOR_CHAPTERINFO, element:ChapterInfo},
	{path:APP_ROUTES.COUPONS_LIST, element: CouponsList},
	{path:APP_ROUTES.PROFILE, element:Profile},
	{path:APP_ROUTES.INVOICE_LIST, element:InvoiceList},
	{path:APP_ROUTES.SEGMENTS, element:SegmentUniversityList},
	{path:APP_ROUTES.SEGMENTS_SPECIFIC, element:SegmentList},
	{path:APP_ROUTES.COURSE_INSTALLMENT, element: Installments},
	{path:APP_ROUTES.TRANSACTIONS, element: Transactions},
	{path: "/universities/departments/students/:id", element:UniversityDepartmentStudent},
	{path:"/configurations", element: ConfigurationsList}
];
