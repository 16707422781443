import  Axios  from "axios";
import { axios } from "../../../lib/axios";
import { API_ROUTES } from "../../../utils/api_routes";



export const uploadImage=async(file:any)=>{

	console.log(file,"file")
	const formData = new FormData();
	
	formData.append("file", file)
	

	return await axios.post(API_ROUTES.COMMON.UPLOAD_FILE, formData, {headers:{"Content-Type":"multipart/form-data"}})
}
export const uploadImages=async(files:any)=>{

	console.log(files,"file")
	const formData = new FormData();
  const arr=[]
  for (const image of files) {
    formData.append("files[]", image);
    arr.push(image)
  }
	// formData.append("files[]",files)
	

	return await axios.post(API_ROUTES.COMMON.UPLOAD_FILES, formData, {headers:{"Content-Type":"multipart/form-data"}})
}

export const uploadVideo=async (file:any) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const videoDetailResponse = await Axios.post(
        API_ROUTES.COMMON.UPLOAD_BUNNY_VIDEO,
		{
			
			title:"introVideo"
		},
        {
          headers: {
            "AccessKey": process.env.REACT_APP_BUNNY as string,
			"Content-Type": "application/json"
          },
        }
      );
	  



      const videoId= videoDetailResponse.data.guid;
	  const VideoAdd = await axios.put(
        `${API_ROUTES.COMMON.UPLOAD_BUNNY_VIDEO}/${videoId}`,
		formData,
        {
          headers: {
            "AccessKey": process.env.REACT_APP_BUNNY as string,
          },
        }
      );

	 
	  return videoId

    } catch (error) {
      console.error("Error uploading video:", error);
    }
  };